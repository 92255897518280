<template>
  <div>
    <group-title
      v-if="items.length"
      :selected-items="selectedItems"
      :items="items"
      @select="selectAll"
    >
      <field-selector :type="type" />
      <show-only-filled
        :items="items"
        :type="type"
      />
    </group-title>
    <v-virtual-scroll
      ref="virtualScroll"
      :items="items"
      :height="virtualScrollHeight"
      :bench="5"
      :item-height="virtualScrollItemHeight"
      class="px-0 rounded ware-detail-virtual-scroll"
    >
      <template #default="{item, index}">
        <woods-details-item
          :key="index"
          :wood="item"
          :index="index"
          :woods="items"
          :selected="selectedItems"
          @add="addWoodsDetail"
          @remove="removeWoodsDetail"
          @select="selectElement"
        />
      </template>
    </v-virtual-scroll>
  </div>
</template>
<script>
import WoodsDetailsItem from "@/components/zvt/goods/ware-details/woods-details-item.vue";
import GroupTitle from "@/components/zvt/goods/ware-details/group-title.vue";
import WareDetailGroupSelectMixin from "@/mixins/ware-detail-group-select.mixin";
import {mapGetters} from "vuex";
import FieldSelector from "@/components/zvt/goods/ware-details/field-selector.vue";
import wareDetailsVirtualScrollMixin from "@/components/zvt/goods/ware-details/ware-details-virtual-scroll.mixin";
import {eventBus} from "@/bus";
import {UPDATE_WARE_DETAIL} from "@/components/zvt/goods/ware-details/events";
import ShowOnlyFilled from "@/components/zvt/goods/ware-details/show-only-filled.vue";
import {WOODS_TYPE} from "@/components/zvt/goods/ware-details/fields";

export default {
  components: {ShowOnlyFilled, FieldSelector, GroupTitle, WoodsDetailsItem},
  mixins: [WareDetailGroupSelectMixin, wareDetailsVirtualScrollMixin],
  props: {
    wareId: {
      required: true,
      type: Number
    },
    items: {
      required: true,
      type: Array
    },
    type: {
      validator: (prop) => typeof prop === "string" || prop === null,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      selectedWares: 'zvt/getSelectedWares',
    }),
  },
  created() {
    eventBus.$on(UPDATE_WARE_DETAIL, this.updateWoodsDetail)
  },
  beforeDestroy() {
    eventBus.$off(UPDATE_WARE_DETAIL, this.updateWoodsDetail)
  },
  methods: {
    updateWoodsDetail({index, item}) {
      this.$emit("update", {type: WOODS_TYPE, item, index})
    },
    addWoodsDetail(lastWareSortIndex) {
      const sortIndex = lastWareSortIndex + 1
      this.$store.dispatch('zvt/createWoodDetails', {
        wareId: this.wareId,
        sortIndex
      }).then(res => {
        this.$emit("add", {type: WOODS_TYPE, item: res.data})
      }).catch(() => this.$error())
    },
    removeWoodsDetail({id, index}) {
      this.$store.dispatch('zvt/deleteWoodDetails', {id}).then(() => {
        this.clearSelected()
        this.$emit("delete", {type: WOODS_TYPE, index})
      }).catch(() => this.$error())
    }
  }
}

</script>
